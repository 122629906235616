<script lang="ts" setup>
const props = defineProps({
  placeholder: {
    type: String,
    default: '全站搜尋'
  },
  color: {
    type: String,
    default: ''
  },
  radius: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String
  },
  icon: {
    type: String,
    default: 'icon-search'
  },
  handleEvent: {
    type: Function as PropType<() => void>,
    default: () => { }
  }
})
const emit = defineEmits(['update:modelValue'])

</script>

<template>
  <div class="search" :class="[color, { radius }]">
    <input type="text" :value="modelValue"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)" class="search__input"
      :placeholder="props.placeholder" />
    <button class="search__button" title="搜尋按鈕" v-if="props.icon" @click="handleEvent">
      <svg-icon :name="props.icon" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.search {
  display: flex;
  width: 100%;
  height: toRem(48);
  background-color: $color-brand-4;
  border-radius: toRem(100);
  overflow: hidden;

  &.radius {
    border-radius: toRem(150);
  }

  &.white {
    background-color: $color-white-1;
  }

  &.gray-2 {
    background-color: $color-gray-2;

    .search {
      &__input {
        color: $color-white-1;

        &::placeholder {
          color: $color-white-1;
        }
      }

      &__button {
        color: $color-white-1;
      }
    }
  }

  &__input {
    width: calc(100% - toRem(48));
    height: toRem(48);
    padding-left: toRem(20);
    padding-right: toRem(20);
    border: none;
    background: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $color-gray-1;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: toRem(48);
    height: toRem(48);
    background: none;
    border: none;
    cursor: pointer;
    color: $color-black-1;

    svg {
      width: toRem(24);
      height: toRem(24);
    }
  }
}
</style>
